import React, { useEffect, useState } from "react";
import { planetWiseServer } from "../../server";
import { useNavigate } from "react-router-dom";

export const ResetPassword = (props)=>{

  let [errorMessage,setErrorMessage] = useState('');

  let [showSuccessMessage,setShowSuccessMessage] =useState(false);

  const verifyAndSubmitPassword = (e)=>{
    e.preventDefault();
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);
    if(formProps['new-password']!=formProps['confirm-password']){
      setErrorMessage("Passwords didn't match");
    }else{
        submitPassword(formProps)
    }
  }
  const submitPassword = async (formData)=>{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body:JSON.stringify({username:props.username,otpVerified:true,newPassword:formData['new-password'],verifyPassword:formData['confirm-password']})
    };
    let res = await fetch(`${planetWiseServer}/accessmanager/user/v1/forgot-password`, requestOptions); 
    if(res.status==200) {
      setErrorMessage('');
      setShowSuccessMessage(true);}
    else setErrorMessage((await res.json()).message);
  }

  const navigate = useNavigate();

  return <>
     <p style={{fontSize:'1.3rem',fontWeight:'600'}}>Reset Password</p>
{!showSuccessMessage?<>
  <form style={{width:"90%",display:"flex",flexDirection:'column'}} onSubmit={(e)=>{verifyAndSubmitPassword(e)}}>
  <div style={{ display: "flex", flexDirection: "column",  marginBottom: "15px" }}>
            <label style={{ marginBottom: "10px" }} for='new-password' >New Password<span style={{color:"red"}}>*</span></label>
            <input autoComplete="off" required id='new-password' className="pw-login-input" name="new-password" placeholder="New Password" type="password" />
        </div>
        <div style={{ display: "flex", flexDirection: "column",  marginBottom: "15px" }}>
            <label style={{ marginBottom: "10px" }} for='confirm-password' >Confirm Password<span style={{color:"red"}}>*</span></label>
            <input autoComplete="off" required id='confirm-password' className="pw-login-input" name="confirm-password" placeholder="Confirm Password" type="password" />
        </div>
        <input className="pw-login-input pw-login-submit" type="submit" value="Reset password" />
  </form>
  {errorMessage}
  <button style={{padding:'10px 30px',marginTop:"15px",cursor:'pointer',fontSize:'1.1rem',outline:'0',border:0}} onClick={()=>{window.location.href = navigate("/login")}}>Back to sign in</button>
  </>
  :<div>
    <div>Password reset successful</div>
    <button style={{padding:'10px 30px',marginTop:'20px',backgroundColor:'#59ce90',color:"white",cursor:'pointer',fontSize:'1.1rem',outline:'0',border:0}} onClick={()=>{window.location.reload()}}>Back to sign in</button>
  </div>
}
  </>
}