import React, { useEffect, useState } from "react";
import './Auth.css';
import { Login } from "./login/Login";
import { Register } from "./register/Register";
import { ForgotPassword } from "./forgot-password/ForgotPassword";
import useVH from "react-viewport-height";
import { useNavigate } from "react-router-dom";


export const  Auth = (props)=>{
  const vh =  useVH();
  let [isSignIn,setIsSignIn] = useState(true);
  let [isMobile,setIsMobile] = useState(window.innerWidth<700);
 let [showRegistrationOtp,setShowRegistrationOtp] = useState(false);
 let[showForgotPassword,setShowForgotPassword] = useState(false);

 const navigate = useNavigate();
 const handleForgotPasswordClick = () => {
  setShowForgotPassword(true);
};
 const handleSignUpClick  = ()=>{
  setShowRegistrationOtp(true);
  setIsSignIn(false);
 }
 let [username,setUsername]=useState('');
 let [password,setPassword]=useState('');
  return <div style={{display:"flex",overflow:'hidden'}}>
       {!isMobile? <div style={{width:"60vw",height:`${vh*100}px`}}>
          <img src='/landing.png' style={{height:`${vh*100}px`,width:'100%',objectFit:'cover',objectPosition:'top'}}/>
        </div>:""
}
        <div style={{width:isMobile?"100vw":"40vw"}}>
          <div style={{padding:"40px 6vw",display:"flex",flexDirection:"column"}}>
            {isMobile?
            <img src="/gm-full-logo-beta.svg" style={{margin:`auto auto ${vh*5}px auto`,width:"80vw"}}/>
            :""}
          
              {!showForgotPassword && !showRegistrationOtp ?
              <div> <div style={{display:"flex",flexDirection:'row',alignItems:"center"}}>
                    <span onClick={(e)=>{setIsSignIn(true);navigate("/login")}} style={{fontWeight: isSignIn?"bold":"",fontSize:isSignIn?"1.2rem":"1.1rem",color:isSignIn?"black":"rgb(128,128,128)",cursor:"pointer"}}>Log in</span>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <span onClick={(e)=>{setIsSignIn(false);navigate("/register")}}  style={{fontWeight: !isSignIn?"bold":"",fontSize:!isSignIn?"1.2rem":"1.1rem",color:!isSignIn?"black":"rgb(128,128,128)",cursor:"pointer"}}>Sign up</span>
                </div>
                <hr style={{width:"100%",margin:`${vh*1.5}px 0 ${vh*2.5}px 0`}}/>
                </div> : ""}

                <div>
                  {isSignIn? <Login onForgotPasswordClick={handleForgotPasswordClick} onSignUpClick={handleSignUpClick}  setPassword={setPassword} setUsername={setUsername} setIsLoggedIn={props.setIsLoggedIn}></Login>:
                  <Register password={password}  username={username}  onSignUpClick={handleSignUpClick} isLoggedIn={props.setIsLoggedIn}></Register>}
                </div>
                <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                 <span style={{fontSize:'0.9rem'}}>Powered by</span> &nbsp;<a href="https://planetwise.io" target="_blank"> <img style={{height:"40px"}} src="/planetwise-new-logo.png"/></a>
                </div>
          </div>
        </div>
      </div>
}

export default Auth;


