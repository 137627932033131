import React, { useEffect, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const Speech = (props) => {
  const {
    listening,
    transcript,
    finalTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  useEffect(()=>{
      props.setQuestion(transcript);
      if(finalTranscript){
          props.processMessage(finalTranscript);
      }
  },[transcript,finalTranscript]);

  return (
    browserSupportsSpeechRecognition?
    <>
      <img src="/mic.png" style={{display:listening?"none":"block",height:"25px",cursor:"pointer"}} onClick={SpeechRecognition.startListening}/>
      <img src="/stop.png"  style={{display:listening?"block":"none",height:"25px",cursor:"pointer"}} onClick={ SpeechRecognition.stopListening}/>
    </>:""
  );
};
export default Speech;