import React, { useEffect, useState } from "react";
import OtpInput from 'react-otp-input';
import './Otp.css';


export const Otp = (props)=>{
let [otp,setOtp] = useState('');
const fillOtpAndVerify = (e)=>{
  setOtp(e);
  if(e.length==6){
    props.submitOtp(e);
  }
}
let [timer,setTimer]= useState(30);
let [resend,setResend] = useState(0);

const resendOtp = ()=>{
  if(timer==0){
    setResend(resend+1);
    props.resend();
  }
}
useEffect(()=>{
  setTimer(30);
},[resend])

useEffect(()=>{
  setTimeout(()=>{
    if(timer>0){
      setTimer(timer-1);
      }
  },1000)
},[timer]);

return <>
          <OtpInput
            value={otp}
            inputType="tel"
            onChange={fillOtpAndVerify}
            numInputs={6}
            inputStyle="pw-login-input"
            renderSeparator={<span style={{display:"inline-block",position:'relative',top:"-5px"}} >&nbsp;-&nbsp;</span>}
            renderInput={(props) => <input  {...props} input />}
          />
          <div style={{display:'flex',justifyContent:"space-between",width:"80%"}}>
          <div style={{display:'flex',alignItems:"center",cursor:'pointer',color:"#59ce90",fontSize:"14px"}} onClick={(e)=>{resendOtp()}}><img style={{height:'15px',width:'15px',marginRight:"3px",color:"#59ce90"}} src='/refresh.png'/>Resend Otp</div>
          <span>{timer>0?timer+'s':''}</span>
          </div>
          </>
}