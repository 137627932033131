import './App.css';
import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
// import { v4 } from 'uuid';
import {SideBar} from './sidebar/SideBar';
import { server } from './server';
import { Login } from './auth/login/Login';
import { Register } from './auth/register/Register';
import { ForgotPassword } from './auth/forgot-password/ForgotPassword';
import {Chat} from './chat/Chat';
import { Otp } from './auth/otp/Otp';
import { ResetPassword } from './auth/reset-password/ResetPassword';

import { Auth } from './auth/Auth';
import Speech from './speech/Speech';
import useVH from 'react-viewport-height';
function App() {
  const vh= useVH();
  let [refresh,setRefresh] = useState(0);
  let [isLoggedIn,setIsLoggedIn] = useState(false);
  let [createNewChat,setCreateNewChat] = useState(0);
  let [isMobile,setIsMobile] = useState(window.innerWidth<700);
  const [userId,setUserId] = useState(localStorage.getItem('userId'));
 const [messages,setMessages] = useState([]);
 const [question,setQuestion] = useState('');
 const handleKey =async (e)=>{
    if(e.key =="Enter"){
      processMessage(question);
    }
 }
 const handleInputChange = async (e)=>{
    setQuestion(e.target.value);
 }
 const setLogin=(isLogin)=>{
    setIsLoggedIn(isLogin);
    if(isLogin){
      setUserId(localStorage.getItem('userId'));
    }
 }
 const newChat = ()=>{
  setMessages([]);
  setChatId(null);
  setCreateNewChat(++createNewChat);
  setShowSideBar(false);
 }
 const updateUserId=(userId)=>{
  localStorage.setItem('userId',userId);
  setUserId(userId);
}

 useEffect(()=>{
    if(localStorage.getItem("token")){
      setIsLoggedIn(true);
    }
    if(localStorage.getItem('userId')){
      setUserId(localStorage.getItem('userId'));
    }
    if(window.location.href.split('?').length==2){
      let params  = new URLSearchParams(window.location.href.split('?')[1]);
      if(params.get('token').length){
        localStorage.setItem('token',params.get('token'));
        setIsLoggedIn(true);
      }
      if(params.get('userId').length){
        updateUserId(params.get('userId'));
      }
      window.location = window.location.href.split('?')[0];
    }

 },[])

 const openChat = async (chatId)=>{
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json','Authorization': localStorage.getItem('token') },
  };
  // https://qa.greenmirror.ai/api
  const res = await fetch(`${server}/chats/${chatId}`, requestOptions); 
  if(res.status==400){
    localStorage.removeItem('token');
    window.location.reload();
    return;
  }
  const data = await res.json();
  setMessages([...data.map(message =>{return {agent:message.by,message:message.data}})]);
  setChatId(chatId);
  setShowSideBar(false);
 }

  let [chatId,setChatId] = useState(null);
  const processMessage = async (message)=>{
    if(message.trim()){
      messages.push({agent:"user",message:message})
      messages.push({agent:"bot",message:'<img  height="30px" src="loading-msg.gif">'})
    setMessages([...messages]);
    setQuestion("");
    setTimeout(()=>{
      document.getElementById("messages").scrollTop = document.getElementById("messages").scrollHeight;
    },100);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json','Authorization': localStorage.getItem('token') },
      body: JSON.stringify({ message: message,chatId:chatId,userId:userId }),
    };
    // https://qa.greenmirror.ai/api
    const res = await fetch(server+"/conversation", requestOptions); 
    if(res.status==400){
      localStorage.removeItem('token');
      window.location.reload();
      return;
    }
    const data = await res.json();
    messages.pop();
    messages.push({agent:"bot",message:data.result});
    if(!chatId){
      setChatId(data.chat_id);
      setRefresh(++refresh);
    }
  
    setMessages([...messages]);
    setTimeout(()=>{
      document.getElementById("messages").scrollTop = document.getElementById("messages").scrollHeight;
    },100);
    }
  }
  let [showSideBar,setShowSideBar] = useState(false);
  const openSideBar = ()=>{
    setShowSideBar(true);
  }
  return (
    <>
    <Router>
        <Routes>
        <Route
            path="/"
            element={
              localStorage.getItem("token") ? (
                <Chat/>
              ) : (
                <Navigate replace to={"/login"} />
              )
            }

          >
          <Route path='chat' element={<Chat/>}   ></Route>
      </Route>
          {/* <Route element={<Home />} >
          
        </Route> */}
        <Route path="/login" element={<Auth setIsLoggedIn={setLogin }/>} /> 
        <Route path='/register' element={<Auth/>} setIsLoggedIn={setLogin }/>
        
          <Route path="/forgotpassword" element={<Auth />} />
          <Route path="/verifyotp" element={<Auth/>} />
          <Route path="/verifypassword" element={<ForgotPassword />} />
          <Route path="/resetpassword" element={<Auth />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </Router>


    {/*<isLoggedIn?
    <>
    {!isMobile?
    <div style={{position:"sticky",background:"white",top:"0px",height:`${vh*10}px`,display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"start",borderBottom:"1px solid #E6E6E6",padding:"15px",boxSizing:"border-box"}}>
        
    <img style={{height:`${vh*10}px`,marginRight:"10px"}} src="gm-full-logo.svg"/>
    
    
    </div>:""}
    <div  style={{background:"#F5F7FA",display:"flex",flexDirection:"row",height:isMobile?`${vh*100}px`:`${vh*90}px`}} >

      { isMobile? <div style={{width:"100vw",height:`${vh*100}px`,zIndex:1,display:showSideBar?"block":"none",position:"fixed",top:0}}>
      <SideBar show={setShowSideBar} createNewChat={createNewChat} refresh={refresh} userId={userId} openChat={openChat} newChat={newChat}></SideBar>
      </div>:
      <div style={{width:"24%",height:`${vh*90}px`,position:"sticky",top:`${vh*10}px`}}>
      <SideBar createNewChat={createNewChat} refresh={refresh} openChat={openChat} userId={userId} newChat={newChat}></SideBar>
      </div>
}
      <div style={{width:isMobile?"100%":"80%"}}>
      { isMobile?<div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between",padding:"0px 10px",background:"white"}}>
        <img onClick={openSideBar} style={{height:"30px",padding:"10px"}}  src="menu.svg"/>
        <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
        <img style={{height:`${vh*10}px`,marginRight:"10px"}} src="gm-full-logo.svg"/>
        </div>
        <img onClick={newChat} style={{height:"20px",padding:"10px",background:"#59CE90",borderRadius:"50%",marginRight:"10px"}}  src="add.svg"/>

      </div> : ""
      }
      <div id='messages' style={{height:`${vh*70}px`,width:"100%",overflow:"auto",fontFamily:"sans-serif",paddingTop:"20px",paddingBottom:"20px"}}>
        { messages.length?
          messages.map(message=>{
            return (
              <>
            
            <div style={{justifyContent:message.agent=="bot"?"start":"end",width: isMobile?"90%":"70%",display:'flex',margin:"auto",marginBottom:"5px",padding:"10px",textAlign:"left"}}>
            {message.agent=="bot"? 
              <div style={{borderRadius:"50%",marginRight:"5px",background:"white",height:isMobile?"40px":"50px",width:isMobile?"40px":"50px"}}>
                <img style={{height:isMobile?"20px":"30px",padding:"10px"}} src="gm-logo.png"/>
           
              </div>:""}
              <div style={{background:message.agent=="user"?"#E3F2EF":"white",color:"black",padding:"15px",borderRadius:"10px",lineHeight:1.5,letterSpacing:"0.5px"}} dangerouslySetInnerHTML={{__html:message.message}}></div>
                  {message.agent=="user"? <div style={{borderRadius:"50%",background:"white",height:isMobile?"40px":"50px",marginLeft:"5px"}}><img style={{height:isMobile?"20px":"30px",padding:"10px"}} src="user.png"/></div>:""}
             
              </div>
            </>
            )
          }): <div style={{display:"flex",flexDirection:"column",alignItems:"center",height:`${vh*70}px`,width:"100%"}}>
              <img height={"100px"} style={{padding:"15px",marginTop:"auto"}} src="gm-logo.png"/>

              <p>Ask your AI assistant <img src='/sparkle.png'/></p>
              <p>Get started now</p>
              <div style={{display:"grid",width:isMobile?"95%":"80%",margin:`${vh*10}px auto ${vh*5}px auto`,gap:"20px",gridTemplateColumns:"auto auto"}}>
              <div onClick={()=>processMessage("What are the scopes in carbon emissions?")} className="gm-sample-message">What are the scopes in carbon emissions?</div>
              <div onClick={()=>processMessage("What is CO2 effect on climate change?")}  className="gm-sample-message">What is CO2 effect on climate change?</div>
              <div  onClick={()=>processMessage("What is GHG protocol?")}  className="gm-sample-message">What is GHG protocol?</div> 
              <div onClick={()=>processMessage("What are carbon credits?")} className="gm-sample-message">What are carbon credits?</div>
              </div>
          </div>
        }
      </div>
      <div style={{height:`${vh*10}px`,display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
        <div style={{position:"relative",margin:"auto",width: isMobile?"90%":"65%"}}>
        <input onChange={handleInputChange} className='gm-input' placeholder='Ask Green Mirror (Beta)' value={question}  onKeyUp={(e)=>handleKey(e)} style={{width:"100%",height:`${vh*6}px`,borderRadius:"25px",padding:"10px 0px 10px 2%",paddingRight:isMobile?"20%":"10%",fontSize:"1rem",boxSizing:"border-box",fontFamily:"sans-serif",fontWeight:"500"}}/> 
        <div style={{position:"absolute",top:"11px",right:"50px"}}>
        <Speech setQuestion={setQuestion} processMessage={processMessage} ></Speech>
        </div>
        <img onClick={(e)=>processMessage(question)} style={{position:"absolute",cursor:"pointer",top:isMobile?"5px":"3.5px", right:"-20px",filter:"opacity(0.8)",right:"2px",scale:"0.9"}} src='arrow_send.png'/>
        <p style={{textAlign:"center",fontSize:"12px",fontWeight:"300", marginTop:"2rem"}}>AI generated content maybe inaccurate. Always check for accuracy.</p>
        </div>
        </div>
        </div>
    </div>
    </>:<Auth setIsLoggedIn={setLogin}></Auth>
      </>*/}
      </>
  );
}

export default App;
