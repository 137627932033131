import React, { useEffect, useState, Fragment } from "react";
import './Login.css';
import { securityServer } from "../../server";
import { Button, Dialog } from "@material-ui/core";

const SessionsListView = (props) => {
  const { open, onClose, isDialog, username, temporaryToken } = props;

  const [activeSessions, setActiveSessions] = useState([]);

  const fetchActiveSessions = async () => {
    const url = new URL(`${securityServer}/security/v1/sessions`);
    url.searchParams.append('username', username);
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json','Authorization': temporaryToken },
    };
    const res = await fetch(url, requestOptions);
    const data = await res.json();
    if (data?.length) {
      if (isDialog && data.length < 2) {
        onClose();
      } else {
        const sessions = [];
        data.forEach((eachSession) => {
          sessions.push({
            ...eachSession,
            currentSession: false,
          });
        });
        setActiveSessions(sessions);
      }
    } else {
      setActiveSessions([]);
    }
  };

  const parseDate = (message) => {
    return message
      ? new Date(message)?.toString().split(" ").slice(0, 5).join(" ")
      : "-";
  };

  const dateParser = (date) => {
    return new Date(parseDate(date))?.toString()
      .split(" ")
      .slice(0, 5)
      .join(" ")
      .substring(4, 21) + " hrs";
  }

  const handleSessionLogout = async (sessionId) => {
    if (sessionId?.length) {
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json','Authorization': temporaryToken },
        body: JSON.stringify({ sessionId }),
      };
      const url = new URL(`${securityServer}/security/v1/session`);
      const res = await fetch(url, requestOptions);
      if(res) {
        fetchActiveSessions();
      }
    }
  };

  useEffect(() => {
    fetchActiveSessions();
  }, []);

  return (
    <Fragment>
      {isDialog ? (
        <div>
          <Dialog open={open} className="gm-sessions-dialog">
            <div className="gm-sessions-div">
              {activeSessions.length ? (
                <div className="">
                  <div className="gm-header-text">
                    {"Active sessions limit reached"}
                  </div>
                  <div className="gm-header-disc">
                    {"Please log out from other devices to sign in from here"}
                  </div>

                  {activeSessions.map((eachSession, index) => (
                    <div key={index} className="gm-session-div">
                      <div className="gm-session-top-row">
                        <div className="gm-session-date">
                          {dateParser(eachSession.loggedInAt)}
                        </div>
                        <div
                          className="gm-session-logout"
                          onClick={() => {
                            handleSessionLogout(eachSession.sessionIdentifier);
                          }}
                        >
                          Logout
                        </div>
                      </div>
                      <div className="gm-session-bottom-row ">
                        {eachSession.deviceType}
                        {eachSession.deviceType && eachSession.browserType ? " - " : ""}
                        {eachSession.browserType}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="gm-buttons-div">
              <div className="gm-cancel-button" onClick={onClose}>
                Cancel
              </div>
            </div>
          </Dialog>
        </div>
      ) : ("")}
    </Fragment>
  );
};

export default SessionsListView;
