import React, { useEffect, useState } from "react";
import { server, securityServer } from "../server";
import { Tooltip } from 'react-tooltip'
import useVH from "react-viewport-height";

export const  SideBar = (props)=>{
  const vh = useVH();
  let [userId,setUserId] = useState('');
  let [chats,setChats] = useState([]);
  let [isMobile,setIsMobile] = useState(window.innerWidth<700);
  useEffect(()=>{
    setTimeout(()=>{
      setUserId(props.userId);
    },200)
  },[]);

  useEffect(()=>{
    setActiveChatId(null);
  },[props.createNewChat])

  const logout = async () => {
      console.log('logout');
      if(localStorage.getItem('token')) {
        const sessionId = JSON.parse(atob(localStorage.getItem('token').split(".")[1]))?.iss?.sessionId;
        if(sessionId) {
          const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json','Authorization': localStorage.getItem('token') },
            body: JSON.stringify({ sessionId }),
          };
          const url = new URL(`${securityServer}/security/v1/session`);
          const res = await fetch(url, requestOptions);
        }
      }
      localStorage.clear();
      window.location.reload();
  }
  
  useEffect(()=>{
    let  data= async()=>{
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json','Authorization': localStorage.getItem('token') },
      };
      let params = new URLSearchParams( {userId:props.userId});
      // https://qa.greenmirror.ai/api
      const res = await fetch(`${server}/chats?`+params, requestOptions); 
      if(res.status==400){
        localStorage.removeItem('token');
        window.location.reload();
        return;
      }
      const data = await res.json();
      setChats([...data]);
      if(props.refresh){
        setActiveChatId(data[0].id);
      }
    
    }
    data();
  },[props.userId,props.refresh]);
  let [activeChatId,setActiveChatId] = useState(null);
    return (<div style={{color:"white",background:"white",height:"100%",fontWeight:"500",paddingTop:'10px'}}>
     {
        isMobile?
      <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"start",paddingLeft:"20px"}}>
        <img style={{height:"30px"}} onClick={()=>props.show(false)} src="chevron-left.svg"/>
        <div  style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
        <img style={{height:`${10*vh}px`,borderRadius:"50%",marginRight:"10px"}} src="gm-full-logo.svg"/> 
        </div>
        </div>:""
} 
      <button onClick={()=>{props.newChat()}} style={{background:"#59CE90",color:"white",width:"85%",padding:"10px",borderRadius:"25px",fontSize:"1.1rem",display:"flex",flexDirection:"row",justifyContent:"center",margin:"auto",alignItems:"center",outline:"0px",border:"0px",cursor:"pointer",fontWeight:"500"}}>
     
        <img height={"30px"} src="add.svg"/> &nbsp; Start new chat
      </button>
      <br/>
        <div style={{padding:"10px",height:`${vh*65}px`,overflow:"auto"}}>
          {chats.map((chat,index)=>{
            return (
              <>
            <div data-tooltip-id={'chat'+index} data-tooltip-content={chat.title} onClick={()=>{props.openChat(chat.id);setActiveChatId(chat.id)}} style={{padding:"15px",borderRadius:"10px",color:"black",background:activeChatId==chat.id?"#EFFCF5":"",textOverflow: "ellipsis",overflow:"hidden",whiteSpace:"nowrap",cursor:"pointer"}}>{chat.title}</div>
            <Tooltip id={'chat'+index} />
            </>
            );
          })}
        </div>
        <div style={{height:`${vh*10}px`}}>
        <button onClick={()=>{logout()}} style={{background:"#59CE90",color:"white",width:"50%",padding:"10px",borderRadius:"25px",fontSize:"1.1rem",display:"flex",flexDirection:"row",justifyContent:"center",margin:"auto",alignItems:"center",outline:"0px",border:"0px",cursor:"pointer",fontWeight:"500",marginTop:`${vh*3}px`}}>Logout <img src='logout.svg' style={{height:"15px",marginLeft:'5px'}}/></button>
        </div>
    </div>)
}