import React, { useEffect, useState } from "react";
import { Otp } from "../otp/Otp";
import { planetWiseServer } from "../../server";
import { ResetPassword } from "../reset-password/ResetPassword";
import { useNavigate } from "react-router-dom";
import Login from "../login/Login";

export const  ForgotPassword = (props)=>{

  let [showOtpScreen,setShowOtpScreen] = useState(false);
  let [errorMessage,setErrorMessage] = useState('');
  let [username,setUsername] = useState('');
  let [showResetPassword,setShowResetPassword] = useState(false);
  const getUserNameAndSendOtp=async (e)=>{
    e.preventDefault();
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);
    setUsername(formProps.email);
   await sendOtp(formProps.email);

  }
  const sendOtp = async (email)=>{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body:JSON.stringify({username:email})
    };
    let res = await fetch(`${planetWiseServer}/accessmanager/user/v1/forgot-password`, requestOptions); 
    if(res.status==200){ 
      setShowOtpScreen(true);
      handleSignUpClick();
    }
    else setErrorMessage(res.message);
  }
  const submitOtp= async (otp)=>{
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body:JSON.stringify({username:username,otpGenerated:true,otp:otp})
    };
    let res = await fetch(`${planetWiseServer}/accessmanager/user/v1/forgot-password`, requestOptions); 
    if(res.status==200) {
      setShowResetPassword(true);
       navigate("/resetpassword");
    }
    else setErrorMessage((await res.json()).message)
    
  }
  const resendOtp=async ()=>{
      sendOtp(username)
  }

  const navigate = useNavigate();

  const handleSignUpClick = () => {
    setShowOtpScreen(true);
    navigate("/verifyotp");
    
    const onOtpScreen = props?. onSignUpClick;
    if (onOtpScreen) {
      onOtpScreen(); 
    }
  };
  
  const handleBackToSignInClick = () => {
    //window.location.reload();
    window.location.href = navigate("/login");
  };

  return <>
  {!showResetPassword?
  <>
  {!showOtpScreen?
  <>
  <div style={{fontSize:'24px',fontWeight:'700',display: "flex",flexDirection: 'row',gap: "50px", borderBottom:"1px solid #d7dfe9",padding:"4px",width:"100%"}}>Forgot Password</div>
      <div style={{margin:"1rem",width:"100%", fontSize:"14px",color:"#596981",marginBottom:"2rem",lineHeight:"20px"}}>Enter your email address below to receive instructions on how to reset
          your password </div>
    <form style={{width:"100%",display:"flex",flexDirection:'column'}} onSubmit={(e)=>getUserNameAndSendOtp(e)}>
        <div style={{ display: "flex", flexDirection: "column",  marginBottom: "15px" }}>
            <label style={{ marginBottom: "10px" }} for='email' >Email ID<span style={{color:"red"}}>*</span></label>
            <input required id='email' className="pw-login-input" name="email" placeholder="Email Address" type="email" />
        </div>
        <input className="pw-login-input pw-login-submit w-full" type="submit" value="Send OTP"/>{/* onClick={() => handleSignUpClick()} />*/}
    </form>
    </>
    :<>
    <div style={{fontSize:'24px',fontWeight:'700',display: "flex",flexDirection: 'row',gap: "50px", borderBottom:"1px solid #d7dfe9",padding:"4px",width:"100%"}}>Verify Account</div>
      <div style={{margin:"1rem",width:"100%", fontSize:"14px",color:"#596981",marginBottom:"2rem"}}>OTP Sent to Registered Mail To Verify Account</div>
    <h3>Enter the OTP</h3>
    <Otp resend={resendOtp}  submitOtp={submitOtp} ></Otp>
    {errorMessage}
    </>
}
    <button style={{padding:'10px 30px',marginTop:"15px",cursor:'pointer',fontSize:'1.1rem',outline:'0',border:0}} onClick={() => handleBackToSignInClick()} >Back to sign in</button>
    </>
    :<ResetPassword username={username}></ResetPassword>
  }
  </>
}